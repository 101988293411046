

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BsTrash, BsPencil } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';



function User() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);



const navigate = useNavigate()



  useEffect(() => {
    fetchUsers();
    fetchUser();
  }, []);

  useEffect(() => {
    axios.get('https://serverdb.rmaplc.com')
        .then(res => {
            if (res.data.valid) {
                setName(res.data.username);
            } else {
                navigate('/login');
            }
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
      }, []);
      
  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://serverdb.rmaplc.com/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await fetch('https://serverdb.rmaplc.com/user', {
        method: 'GET',
        credentials: 'include', // Include credentials (session cookie)
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
      } else {
        throw new Error('Error fetching user');
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const deleteUser = async (userId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this user?');
    if (confirmDelete) {
      try {
        await axios.delete(`https://serverdb.rmaplc.com/user/${userId}`);
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

//   if (!user || user.role_id !== 1) {
//     console.log('Authorization failed:', user); // Check if user authorization fails
//     return <div>You are not authorized to access this page.</div>;
// }

const getRoleName = (roleId) => {
  if (roleId === 1) {
    return 'Admin';
  } else if (roleId === 2) {
    return 'Manager';
  } else if (roleId === 3) {
    return 'Regular';
  } else {
    return 'Unknown';
  }
};

if (loading) {
  return (
    <main className='main-container'>
    <div className='parent-container'>
      <div className='loading'></div>
    </div>
    </main>
  );
}


  return (
    <main className="main-container">
      <div className="user-list-container">
        <h2 className="user-list-header">User List</h2>
        <table className="user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Role</th>
              <th>Delete</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="user-row">
                <td>{user.username}</td>
                <td>{getRoleName(user.role_id)}</td>
                <td>
                  <button onClick={() => deleteUser(user.id)} className="delete-button">
                    <BsTrash />
                  </button>
                </td>
                <td>
                  <Link to={`/update/${user.id}`} className="update-button">
                    <BsPencil />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}

export default User;






