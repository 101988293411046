import React from 'react'
import Add from '../AddUser/Add'
import User from '../User/User'

function Edit({user}) {
//   if (!user || user.role_id !== 1) {
//     console.log('Authorization failed:', user); // Check if user authorization fails
//     return <div>You are not authorized to access this page.</div>;
// }
  return (
    <div className='main-container'>
      <h1>Hellow </h1>
      <Add />
      {/* <User /> */}
    </div>
  )
}

export default Edit
