// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// // import firebase from 'firebase/app';
// // import 'firebase/messaging';

// function Form() {
//   const [name, setName] = useState('');
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     axios.get('https://serverdb.rmaplc.com')
//       .then(res => {
//         if (res.data.valid) {
//           setName(res.data.username);
//         } else {
//           navigate('/login');
//         }
//       })
//       .catch(err => console.log(err))
//       .finally(() => setLoading(false));
//   }, []);

//   const [formData, setFormData] = useState({
//     SAD: '',
//     resever: '',
//     amount: '',
//     reason: '',
//     // Remove submitterName and status from formData
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   useEffect(() => {
//     // Set submitterName after name is fetched
//     setFormData(prevState => ({
//       ...prevState,
//       submitterName: name
//     }));
//   }, [name]); // Trigger effect when name changes

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { SAD, resever, amount, reason } = formData;
//     try {
//       await axios.post('https://serverdb.rmaplc.com/submit_form', { SAD, resever, amount, reason, submitterName: name, status: 'Pending' });
//       alert('Form submitted successfully!');
//       // Clear input fields
//       setFormData({
//         SAD: '',
//         resever: '',
//         amount: '',
//         reason: '',
//       });
//       // Navigate to "/"
//       navigate('/today_data');
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       alert('An error occurred while submitting the form. Please try again.');
//     }
//   };

//   if (loading) {
//     return (
//       <main className='main-container'>
//       <div className='parent-container'>
//         <div className='loading'></div>
//       </div>
//       </main>
//     );
//   }
  

//   return (
//     <main className='main-container'>
//       <div className='grid-container'>
//         <div className="form-container">
//           <h2 className='subtitle'>Submit Form</h2>
//           <form onSubmit={handleSubmit}>
//             <input type="number" name="SAD" value={formData.SAD} onChange={handleChange} className="form-input" placeholder="SAD" required /><br /><br />
//             <input type="text" name="resever" value={formData.resever} onChange={handleChange} className="form-input" placeholder="Resever" required /><br /><br />
//             <input type="number" name="amount" value={formData.amount} onChange={handleChange} className="form-input" placeholder="Amount" required /><br /><br />
//             <textarea name="reason" value={formData.reason} onChange={handleChange} className="form-textarea" placeholder="Reason" required></textarea><br /><br />
//             {/* <input type="text" name="submitterName" value={formData.submitterName} className="form-input" placeholder="Your Name" readOnly /><br /><br />
//             <input type="text" name="status" value={formData.status} className="form-input" placeholder="Status" readOnly /><br /><br /> */}
//             <button type="submit" className="form-submit-button">Submit</button>
//           </form>
//         </div>
//       </div>
//     </main>
//   );
// }

// export default Form;













import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Form() {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // New state to track form submission
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://serverdb.rmaplc.com')
      .then(res => {
        if (res.data.valid) {
          setName(res.data.username);
        } else {
          navigate('/login');
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const [formData, setFormData] = useState({
    SAD: '',
    resever: '',
    amount: '',
    reason: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      submitterName: name
    }));
  }, [name]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);

    const { SAD, resever, amount, reason } = formData;
    try {
      await axios.post('https://serverdb.rmaplc.com/submit_form', { SAD, resever, amount, reason, submitterName: name, status: 'Pending' });
      alert('Form submitted successfully!');
      setFormSubmitted(true);
      setFormData({
        SAD: '',
        resever: '',
        amount: '',
        reason: '',
      });
      navigate('/today_data');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    } finally {
      setFormLoading(false);
    }
  };

  if (loading) {
    return (
      <main className='main-container'>
        <div className='parent-container'>
          <div className='loading'></div>
        </div>
      </main>
    );
  }

  return (
    <main className='main-container'>
      <div className='grid-container'>
        <div className="form-container">
          <h2 className='subtitle'>Submit Form</h2>
          <form onSubmit={handleSubmit}>
            <input type="number" name="SAD" value={formData.SAD} onChange={handleChange} className="form-input" placeholder="SAD" required /><br /><br />
            <input type="text" name="resever" value={formData.resever} onChange={handleChange} className="form-input" placeholder="Resever" required /><br /><br />
            <input type="number" name="amount" value={formData.amount} onChange={handleChange} className="form-input" placeholder="Amount" required /><br /><br />
            <textarea name="reason" value={formData.reason} onChange={handleChange} className="form-textarea" placeholder="Reason" required></textarea><br /><br />
            <button type="submit" className={`form-submit-button${formSubmitted ? ' disabled' : ''}`} disabled={formLoading || formSubmitted}>
              {formLoading ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
}

export default Form;
