// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../../style.css';
// import { useNavigate } from 'react-router-dom';

// const SignUp = ({ user}) => {
//   const [adminUsername, setAdminUsername] = useState('');
//   const [adminPassword, setAdminPassword] = useState('');
//   const [managerUsername, setManagerUsername] = useState('');
//   const [managerPassword, setManagerPassword] = useState('');
//   const [userUsername, setUserUsername] = useState('');
//   const [userPassword, setUserPassword] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [name, setName] = useState('');
//   const navigate = useNavigate();

  

//   const handleSignUp = (username, password, roleId) => {
//     axios
//       .post('https://serverdb.rmaplc.com/add_users', { username, password, role_id: roleId })
//       .then((res) => {
//         console.log(res.data);
//         // Optionally, reset the form fields after successful signup
//         switch (roleId) {
//           case 1:
//             setAdminUsername('');
//             setAdminPassword('');
//             break;
//           case 2:
//             setManagerUsername('');
//             setManagerPassword('');
//             break;
//           case 3:
//             setUserUsername('');
//             setUserPassword('');
//             break;
//           default:
//             break;
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     axios.get('https://serverdb.rmaplc.com')
//         .then(res => {
//             if (res.data.valid) {
//                 setName(res.data.username);
//             } else {
//                 navigate('/login');
//             }
//         })
//         .catch(err => console.log(err))
//         .finally(() => setLoading(false));

// }, []);

// //   if (!user || user.role_id !== 1) {
// //     console.log('Authorization failed:', user); // Check if user authorization fails
// //     return <div>You are not authorized to access this page.</div>;
// // }

// if (loading) {
//   return (
//     <main className='main-container'>
//     <div className='parent-container'>
//       <div className='loading'></div>
//     </div>
//     </main>
//   );
// }
//   return (
//     <div className="admin-form">
//       <h2 >Add Admin</h2>
//       <form>
//         <input
//           type="text"
//           placeholder="Username"
//           value={adminUsername}
//           onChange={(e) => setAdminUsername(e.target.value)}
//           className="input-field"
//           required
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={adminPassword}
//           onChange={(e) => setAdminPassword(e.target.value)}
//           className="input-field"
//           required
//         />
//         <button type="submit" onClick={() => handleSignUp(adminUsername, adminPassword, 1)} className="submit-button">
//           Add
//         </button>
//       </form>

//       <h2>Add Manager</h2>
//       <form>
//         <input
//           type="text"
//           placeholder="Username"
//           value={managerUsername}
//           onChange={(e) => setManagerUsername(e.target.value)}
//           className="input-field"
//           required
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={managerPassword}
//           onChange={(e) => setManagerPassword(e.target.value)}
//           className="input-field"
//           required
//         />
//         <button type="submit" onClick={() => handleSignUp(managerUsername, managerPassword, 2)} className="submit-button">
//           Add
//         </button>
//       </form>

//       <h2>Add User</h2>
//       <form>
//         <input
//           type="text"
//           placeholder="Username"
//           value={userUsername}
//           onChange={(e) => setUserUsername(e.target.value)}
//           className="input-field"
//           required
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={userPassword}
//           onChange={(e) => setUserPassword(e.target.value)}
//           className="input-field"
//           required
//         />
//         <button type="submit" onClick={() => handleSignUp(userUsername, userPassword, 3)} className="submit-button">
//           Add
//         </button>
//       </form>
//     </div>
//   );
// };

// export default SignUp;










import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../style.css';
import { useNavigate } from 'react-router-dom';

const SignUp = ({ user }) => {
  const [adminUsername, setAdminUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [managerUsername, setManagerUsername] = useState('');
  const [managerPassword, setManagerPassword] = useState('');
  const [userUsername, setUserUsername] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [adminButtonLoading, setAdminButtonLoading] = useState(false);
  const [managerButtonLoading, setManagerButtonLoading] = useState(false);
  const [userButtonLoading, setUserButtonLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = (username, password, roleId) => {

    if (!username || !password) {
      alert('Please fill in all the fields');
      return;
    }

    
    switch (roleId) {
      case 1:
        setAdminButtonLoading(true);
        break;
      case 2:
        setManagerButtonLoading(true);
        break;
      case 3:
        setUserButtonLoading(true);
        break;
      default:
        break;
    }

    axios
      .post('https://serverdb.rmaplc.com/add_users', { username, password, role_id: roleId })
      .then((res) => {
        console.log(res.data);
        switch (roleId) {
          case 1:
            setAdminUsername('');
            setAdminPassword('');
            setAdminButtonLoading(false);
            break;
          case 2:
            setManagerUsername('');
            setManagerPassword('');
            setManagerButtonLoading(false);
            break;
          case 3:
            setUserUsername('');
            setUserPassword('');
            setUserButtonLoading(false);
            break;
          default:
            break;
        }
        navigate('/list_users');
      })
      .catch((err) => {
        console.log(err);
        switch (roleId) {
          case 1:
            setAdminButtonLoading(false);
            break;
          case 2:
            setManagerButtonLoading(false);
            break;
          case 3:
            setUserButtonLoading(false);
            break;
          default:
            break;
        }
      });
  };

  useEffect(() => {
    axios.get('https://serverdb.rmaplc.com')
      .then(res => {
        if (res.data.valid) {
          setName(res.data.username);
        } else {
          navigate('/login');
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <main className='main-container'>
        <div className='parent-container'>
          <div className='loading'></div>
        </div>
      </main>
    );
  }

  return (
    <div className="admin-form">
      <h2>Add Admin</h2>
      <form>
        <input
          type="text"
          placeholder="Username"
          value={adminUsername}
          onChange={(e) => setAdminUsername(e.target.value)}
          className="input-field"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={adminPassword}
          onChange={(e) => setAdminPassword(e.target.value)}
          className="input-field"
          required
        />
        <button type="button" onClick={() => handleSignUp(adminUsername, adminPassword, 1)} className={`submit-button${adminButtonLoading ? ' disabled' : ''}`} disabled={adminButtonLoading}>
          {adminButtonLoading ? 'Adding...' : 'Add'}
        </button>
      </form>

      <h2>Add Manager</h2>
      <form>
        <input
          type="text"
          placeholder="Username"
          value={managerUsername}
          onChange={(e) => setManagerUsername(e.target.value)}
          className="input-field"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={managerPassword}
          onChange={(e) => setManagerPassword(e.target.value)}
          className="input-field"
          required
        />
        <button type="button" onClick={() => handleSignUp(managerUsername, managerPassword, 2)} className={`submit-button${managerButtonLoading ? ' disabled' : ''}`} disabled={managerButtonLoading}>
          {managerButtonLoading ? 'Adding...' : 'Add'}
        </button>
      </form>

      <h2>Add User</h2>
      <form>
        <input
          type="text"
          placeholder="Username"
          value={userUsername}
          onChange={(e) => setUserUsername(e.target.value)}
          className="input-field"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={userPassword}
          onChange={(e) => setUserPassword(e.target.value)}
          className="input-field"
          required
        />
        <button type="button" onClick={() => handleSignUp(userUsername, userPassword, 3)} className={`submit-button${userButtonLoading ? ' disabled' : ''}`} disabled={userButtonLoading}>
          {userButtonLoading ? 'Adding...' : 'Add'}
        </button>
      </form>
    </div>
  );
};

export default SignUp;
