





import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import myImage from './myImage.jpg';
import {
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsFillGearFill,
  BsBoxArrowRight
} from 'react-icons/bs';

function Nav({ openSidebarToggle, OpenSidebar }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get('https://serverdb.rmaplc.com/user')
      .then(res => {
        setUser(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.removeItem('token');
    axios.post('https://serverdb.rmaplc.com/logout')
      .then(response => {
        if (response.data.message === 'Logout successful') {
          window.location.reload();
        } else {
          window.location.reload();

          // Handle logout failure if needed
        }
      })
      .catch(error => {
        console.error('Error during logout:', error);
      });
  };

  
  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          <img src={myImage} alt="My Image" className='img' />
        </div>
        <span className='icon close_icon' onClick={OpenSidebar}>X</span>
      </div>
      <ul className='sidebar-list'>
    
        { (user?.role_id === 1 || user?.role_id === 2) &&
        <Link to={'/dashboard'} className='link' onClick={() => { if (openSidebarToggle) OpenSidebar(); }}>
          <li className='sidebar-list-item'>
            <BsGrid1X2Fill className='icon' /> Dashboard
          </li>
        </Link>
        }
        <Link to={"/add_form"} className='link' onClick={() => { if (openSidebarToggle) OpenSidebar(); }}>
          <li className='sidebar-list-item'>
            <BsFillArchiveFill className='icon' /> Submit Form
          </li>
        </Link>
        {/* Only show the "List of Data" link if user role is 1 or 2 */}
        {(user?.role_id === 1 || user?.role_id === 2) &&
          <Link to="/form_data" className='link' onClick={() => { if (openSidebarToggle) OpenSidebar(); }}>
            <li className='sidebar-list-item'>
              <BsFillGrid3X3GapFill className='icon' /> List all Data
            </li>
          </Link>
        }
        {/* Only show the "Users" link if user role is 1 */}
        {(user?.role_id === 1 ) &&
          <Link to="/list_users" className='link' onClick={() => { if (openSidebarToggle) OpenSidebar(); }}>
            <li className='sidebar-list-item'>
              <BsPeopleFill className='icon' /> Users
            </li>
          </Link>
        }
        <Link to={"/today_data"} className='link' onClick={() => { if (openSidebarToggle) OpenSidebar(); }}>
          <li className='sidebar-list-item'>
            <BsListCheck className='icon' /> Today
          </li>
        </Link>

        {(user?.role_id === 1 ) &&
        <Link to={"/setting"} className='link' onClick={() => { if (openSidebarToggle) OpenSidebar(); }}>
          <li className='sidebar-list-item'>
            <BsFillGearFill className='icon' /> Setting
          </li>
        </Link>
        }

{/* <Link to={"/update_pass"} className='link' onClick={() => { if (openSidebarToggle) OpenSidebar(); }}>
          <li className='sidebar-list-item'>
            <BsFillGearFill className='icon' /> Setting
          </li>
        </Link> */}

        
        <Link onClick={handleLogout} className='link'>
          <li className='sidebar-list-item'>
            <BsBoxArrowRight className='icon' /> Logout
          </li>
        </Link>
      </ul>
    </aside>
  );
}

export default Nav;


