import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../style.css';
import { useNavigate } from 'react-router-dom';
import { FaDollarSign } from 'react-icons/fa';
// import { BsListCheck, BsFillGrid3X3GapFill } from 'react-icons/bs';
import { BsListCheck, BsFillGrid3X3GapFill, BsTrash, BsPencil, BsEyeFill } from 'react-icons/bs';

function Search() {
    const [formData, setFormData] = useState([]);
    const [name, setName] = useState('');
    const [totalAmount, setTotalAmount] = useState(0); // State variable to store the total amount
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        axios.get('https://serverdb.rmaplc.com')
            .then(res => {
                if (res.data.valid) {
                    setName(res.data.username);
                } else {
                    navigate('/login');
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));

    }, []);

    useEffect(() => {
        fetchFormData();
    }, []);

    useEffect(() => {
        // Calculate the total amount when formData changes
        const sum = formData.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
        setTotalAmount(sum);
    }, [formData]);

    const fetchFormData = async () => {
        try {
            const response = await axios.get('https://serverdb.rmaplc.com/form_data');
            setFormData(response.data);
        } catch (error) {
            console.error('Error fetching form data:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    // const filteredFormData = formData.filter(item => item.SAD.toLowerCase().includes(searchTerm.toLowerCase()));
    const filteredFormData = formData.filter(item => item.SAD.toLowerCase() === searchTerm.toLowerCase());


    const uniqueSubmittedSAD = new Set(filteredFormData.map(item => item.SAD));
    const totalSubmittedSAD = uniqueSubmittedSAD.size;

    const totalAmountForSAD = filteredFormData.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

    const deleteData = async (dataId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this Data?");
        if (confirmDelete) {
            try {
                await axios.delete(`https://serverdb.rmaplc.com/delete/${dataId}`);
                fetchFormData();
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        }
    };

    const updateDatas = (dataId) => {
        navigate(`/update_data/${dataId}`);
    };


    const handleStatusChange = async (dataId) => {
        try {
            const currentItem = filteredFormData.find(item => item.id === dataId);
            // Check if current status is not 'Done'
            if (currentItem.status !== 'Done') {
                // Update the status in the database
                await axios.put(`https://serverdb.rmaplc.com/update_status/${dataId}`, { status: 'Done' });
                // Update the status in the local state
                const updatedFormData = filteredFormData.map(item => {
                    if (item.id === dataId) {
                        return { ...item, status: 'Done' };
                    }
                    return item;
                });
                setFormData(updatedFormData);
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    

    if (loading) {
        return (
          <main className='main-container'>
          <div className='parent-container'>
            <div className='loading'></div>
          </div>
          </main>
        );
      }



    const viewData = (dataId) => {
        navigate(`/view_data/${dataId}`);
    };
    return (
        <main className='main-container'>

            <div className='card-container'>
                <div className='card-wrapper'>
                    <div className='payment-card ligh-red'>
                        <div className='card-header'>
                            <div className='amount'>
                                <span className='title'>
                                    Payment amount
                                </span>
                                <span className='amount-value'>
                                    ${totalAmountForSAD}
                                </span>
                            </div>
                            <FaDollarSign className='fas fa-dollar-sign icon-dash' />
                        </div>
                    </div>
                    <div className='payment-card ligh-purple'>
                        <div className='card-header'>
                            <div className='amount'>
                                <span className='title'>
                                    Total SAD
                                </span>
                                <span className='amount-value'>
                                    {totalSubmittedSAD}
                                </span>
                            </div>
                            <BsFillGrid3X3GapFill className='fas fa-dollar-sign dark-purple icon-dash' />
                        </div>
                    </div>
                    <div className='payment-card ligh-green'>
                        <div className='card-header'>
                            <div className='amount'>
                                <span className='title'>
                                    Total Submited
                                </span>
                                <span className='amount-value'>
                                    {filteredFormData.length}
                                </span>
                            </div>
                            <BsListCheck className='fas fa-dollar-sign dark-green icon-dash' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-container">
                <input
                    className='search'
                    type="text"
                    placeholder="Search by SAD"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className='grid-container'>
                <div className='tabular--wrapper'>
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>SAD</th>
                                    <th>Resever</th>
                                    <th>Amount</th>
                                    <th>Reason</th>
                                    <th>Date</th>
                                    <th>Submitter</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredFormData.map(item => (
                                    <tr key={item.id}>
                                        <td>{item.SAD}</td>
                                        <td>{item.resever}</td>
                                        <td>{item.amount}</td>
                                        {/* <td>{item.reason}</td> */}
                                    <td>{item.reason.length > 15 ? `${item.reason.slice(0, 15)}...` : item.reason}</td>

                                        <td>{formatDate(item.submission_date)}</td>
                                        <td>{item.submitter_name}</td>
                                        {/* <td>Pending</td> */}
                                        <td className={item.status === 'pending' ? 'status-pending' : 'status-done'}>
                                        <button onClick={() => handleStatusChange(item.id)} >{item.status}</button>
                                    </td>
                                    <td>
                                        {item.status === 'pending' && (
                                                <>
                                                    <button onClick={() => viewData(item.id)}><BsEyeFill /></button>
                                                    <button onClick={() => deleteData(item.id)}><BsTrash /></button>
                                                    <button className='but' onClick={() => updateDatas(item.id)}><BsPencil /></button>
                                                </>
                                            )}
                                            {item.status === 'Done' && (
                                                <button onClick={() => viewData(item.id)}><BsEyeFill /></button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Search;
