


import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import Footer from './components/Footer/Footer';
import Dashboard from './components/Dashboard/Dashboard';
import Header from './components/Header/Header';
import User from './components/User/User';
import Form from './components/Forms/Forms';
import Nav from './components/Nav/Nav';
import Login from './components/Login/Login';
import Add from './components/AddUser/Add';
import FormDataComponent from './components/FormDataComponent/FormDataComponent';
import Edit from './components/Edit/Edit';
import Dtoday from './components/Dtoday/Dtoday';
import Search from './components/Search/Search';
import Update from './components/Update/Update';
import FormUpdate from './components/FormUpdate/FormUpdate';
import ViewData from './components/ViewData/ViewData';
// import Upass from './components/Upass/Upass';

function App({ isLoginPage}) {
  const [user, setUser] = useState(null);
  // const { id } = useParams();

  // useEffect(() => {
  //   // Fetch user data when the component mounts
  //   const fetchUser = async () => {
  //     try {
  //       const response = await axios.get('https://serverdb.rmaplc.com/user'); // Adjust URL as per your backend route
  //       setUser(response.data);
  //     } catch (error) {
  //       console.error('Error fetching user:', error);
  //     }
  //   };

  //   fetchUser();
  // }, []);


//   useEffect(() => {
//     // Fetch user data when the component mounts
//     const fetchUser = async () => {
//       try {
//         const response = await axios.get('https://serverdb.rmaplc.com/user'); // Adjust URL as per your backend route
//         setUser(response.data);
//       } catch (error) {
//         console.error('Error fetching user:', error);
//       }
//     };

//     fetchUser();
// }, []);

useEffect(() => {
  const fetchUser = async () => {
    try {
      const response = await axios.get('https://serverdb.rmaplc.com/user');
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
      // Handle the error, e.g., set an error state
    }
  };

  fetchUser();
}, []);


  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className={!isLoginPage ? 'grid-container' : ''}>
      {!isLoginPage && (
        <>
          <Header OpenSidebar={OpenSidebar} />
          <Nav openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
        </>
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/add" element={<Add />} />
        <Route path="/add_form" element={<Form />} />
        {/* Pass the user prop to FormDataComponent */}
        <Route path="/form_data" element={<FormDataComponent  />} />
        <Route path="/setting" element={<Edit  />} />
        <Route path="/today_data" element={<Dtoday  />} />
        <Route path="/list_users" element={<User />} />
        <Route path="/search" element={<Search  />} />
        <Route path="/update/:id" element={<Update  />} />
        <Route path="/update_data/:id" element={<FormUpdate  />} />
        <Route path="/view_data/:id" element={<ViewData  />} />
        <Route path="/dashboard" element={<Dashboard   user={user}/>} />
        {/* <Route path="/update_pass/:id" element={<Upass  />} /> */}
      </Routes>
      {/* {!isLoginPage && <Footer />} */}
    </div>
  );
}

export default App;


