import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import '../../style.css'; // Import the CSS file for Login component

const Login = () => {
  const [values, setValues] = useState({
    username: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleInput = (event) => {
    setValues(prev => ({...prev, [event.target.name]: event.target.value})) // Fix here, removed array brackets around event.target.value
  }

  axios.defaults.withCredentials = true

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   axios.post('https://serverdb.rmaplc.com/login', values)
  //     .then(res => {
  //       if (res.data.Login) { // Corrected to access 'Login' instead of 'login'
  //         navigate('/');
  //         window.location.reload();
  //       } else {
  //         alert("No Record");
  //       }
  //       console.log(res);
  //     })
  //     .catch(err => console.log(err));
  // }


  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   axios.post('https://serverdb.rmaplc.com/login', values)
  //     .then(res => {
  //       if (res.data.Login) {
  //         // Check the role_id of the logged-in user
  //         if (res.data.role_id !== 1 && res.data.role_id !== 2) {
  //           navigate('/today_data');
  //         } else {
  //           navigate('/');
  //         }
  //         window.location.reload();
  //       } else {
  //         alert("No Record");
  //       }
  //       console.log(res);
  //     })
  //     .catch(err => console.log(err));
  // }


  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('https://serverdb.rmaplc.com/login', values)
      .then(res => {
        if (res.data.Login) {
          // Check the role_id of the logged-in user
          if (res.data.role_id !== 1 && res.data.role_id !== 2) {
            navigate('/today_data');
            // window.location.reload();

          } else {
            navigate('/today_data');
          }
          // window.location.reload();
        } else {
          alert("No Record");
        }
        console.log(res);
      })
      .catch(err => console.log(err));
  }
  
  useEffect(()=> {
    axios.get('https://serverdb.rmaplc.com')
    .then(res => {
      if(res.data.valid) {
        navigate('/today_data')
      } else {
        navigate('/login')
      }
    })
    .catch(err => console.log(err))
  }, [])
  
  

  return (
    <main className='main-container'>
    <div className="login-container">
      <h2 className="login-heading">Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="username" // Added name attribute
          placeholder="Username"
          className="login-input"
          value={values.username} // Added value attribute
          onChange={handleInput}
        />
        <input
          type="password"
          name="password" // Added name attribute
          placeholder="Password"
          className="login-input"
          value={values.password} // Added value attribute
          onChange={handleInput}
        />
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
    </main>
  );
};

export default Login;









