


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../../style.css'

function Update() {
    const { id } = useParams();
    const navigate = useNavigate();
  // const history = useHistory();
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');


    useEffect(() => {
        axios.get('https://serverdb.rmaplc.com')
            .then(res => {
                if (res.data.valid) {
                    setName(res.data.username);
                } else {
                    navigate('/login');
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));

    }, []);


    useEffect(() => {
        axios.get(`https://serverdb.rmaplc.com/edit/${id}`)
            .then(res => {
                // console.log(res.data);
                setUserData(res.data);
            })
            .catch(err => console.error(err))
            setLoading(false);

    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        axios.put(`https://serverdb.rmaplc.com/user/${id}`, userData)
            .then(res => {
                // console.log(res.data);
                // Handle success, e.g., show a success message
                // history.push('/');
           navigate('/list_users');

            })
            
            .catch(err => console.error(err));
    };

    if (loading) {
        return (
          <main className='main-container'>
          <div className='parent-container'>
            <div className='loading'></div>
          </div>
          </main>
        );
      }

    return (
        <div className="update-container">
            <h1>Update User</h1>
            <form onSubmit={handleUpdate} className="update-form"> 
                <input
                    type="text"
                    placeholder="Username"
                    name="username"
                    value={userData.username || ''}
                    onChange={handleInputChange}
                    className="input-field" 
                    required
                />
                <input
                    type="text"
                    placeholder="Password"
                    name="password"
                    value={userData.password || ''}
                    onChange={handleInputChange}
                    className="input-field" 
                    required
                />
                <button type="submit" className="submit-button"> 
                    Update
                </button>
            </form>
        </div>
    );
}

export default Update;

