
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, useLocation } from 'react-router-dom';

function Root() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return <App isLoginPage={isLoginPage} />;
}

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById('root')
);
