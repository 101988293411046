import React, {useState, useEffect} from 'react'
import {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsTrash, BsJustify} from 'react-icons/bs';
 import '../../style.css'
 import { useNavigate } from 'react-router-dom';
 import axios from 'axios';
import { Link } from 'react-router-dom';

function Header({OpenSidebar}) {
  const [name, setName] = useState('')
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get('https://serverdb.rmaplc.com/user')
      .then(res => {
        setUser(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    axios.get('https://serverdb.rmaplc.com')
      .then(res => {
        if (res.data.valid) {
          setName(res.data.username)
        } else {
          navigate('/login')
        }
      })
      .catch(err => console.log(err))
  }, []);

  return (
    <header className='header'>
        <div className='menu-icon'>
            <BsJustify className='icon' onClick={OpenSidebar}/>
        </div>

        { (user?.role_id === 1 || user?.role_id === 2) &&
      
          <Link  to={'/search'}>
            <div className='header-left'>
              <BsSearch  className='icon'/>
            </div>
          </Link>
        }


        <div className='header-right'>
            {/* <BsFillBellFill className='icon'/> */}
            {/* <BsFillEnvelopeFill className='icon'/> */}
            {name}
            <BsPersonCircle className='icon'/>
        </div>
    </header>
  )
}

export default Header