import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function FormUpdate() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://serverdb.rmaplc.com/form_data/${id}`)
            .then(res => {
                setFormData(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching data for update:', err);
                setLoading(false);
            })
            .finally(() => setLoading(false));

    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://serverdb.rmaplc.com/form_update/${id}`, formData);
            navigate('/dashboard'); // Navigate to the list of users after successful update
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    if (loading) {
        return (
          <main className='main-container'>
          <div className='parent-container'>
            <div className='loading'></div>
          </div>
          </main>
        );
      }

    return (
        <div className="update-container">
            <h1>Update Data</h1>
            <form onSubmit={handleUpdate} className="update-form">
                <input
                    type="text"
                    placeholder="SAD"
                    name="SAD"
                    value={formData.SAD || ''}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <input
                    type="text"
                    placeholder="Resever"
                    name="resever"
                    value={formData.resever || ''}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <input
                    type="number"
                    placeholder="Amount"
                    name="amount"
                    value={formData.amount || ''}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <textarea
                    placeholder="Reason"
                    name="reason"
                    value={formData.reason || ''}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <button type="submit" className="submit-button">
                    Update
                </button>
            </form>
        </div>
    );
}

export default FormUpdate;
