




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaDollarSign } from 'react-icons/fa';
import { BsListCheck, BsFillGrid3X3GapFill, BsTrash, BsPencil, BsEyeFill } from 'react-icons/bs';

function ViewData() {
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();

    // const handleStatusChange = async (dataId) => {
    //     try {
    //         const currentItem = formData.find(item => item.id === dataId);
    //         // Check if current status is not 'Done'
    //         if (currentItem.status !== 'Done') {
    //             // Update the status in the database
    //             await axios.put(`https://serverdb.rmaplc.com/update_status/${dataId}`, { status: 'Done' });
    //             // Update the status in the local state
    //             const updatedFormData = formData.map(item => {
    //                 if (item.id === dataId) {
    //                     return { ...item, status: 'Done' };
    //                 }
    //                 return item;
    //             });
    //             setFormData(updatedFormData);
    //         }
    //     } catch (error) {
    //         console.error('Error updating status:', error);
    //     }
    // };

    const handleStatusChange = async (dataId) => {
        try {
            const currentItem = formData.find(item => item.id === dataId);
    
            // Check if the current status is not 'Done'
            if (currentItem.status !== 'Done') {
                // Update the status in the database
                const response = await axios.put(`https://serverdb.rmaplc.com/update_status/${dataId}`, { status: 'Done' });
    
                // Check if the status update was successful in the backend
                if (response.data.message === 'Status updated successfully') {
                    // Update the status in the local state
                    const updatedFormData = formData.map(item => {
                        if (item.id === dataId) {
                            return { ...item, status: 'Done' };
                        }
                        return item;
                    });
    
                    setFormData(updatedFormData);
                } else {
                    // Handle status update failure if needed
                    console.error('Status update failed:', response.data.message);
                }
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    
    const updateDatas = (dataId) => {
        navigate(`/update_data/${dataId}`);
    };

    // const deleteData = async (dataId) => {
    //     const confirmDelete = window.confirm("Are you sure you want to delete this Data?");
    //     if (confirmDelete) {
    //         try {
    //             await axios.delete(`https://serverdb.rmaplc.com/delete/${dataId}`);
    //             fetchData();
    //         } catch (error) {
    //             console.error('Error deleting user:', error);
    //         }
    //     }
    // };

    useEffect(() => {
        axios.get('https://serverdb.rmaplc.com')
            .then(res => {
                if (!res.data.valid) {
                    navigate('/login');
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));


        const fetchData = async () => {
            try {
                const response = await axios.get(`https://serverdb.rmaplc.com/view_data/${id}`);
                setFormData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching form data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, navigate]);

    if (loading) {
        return (
          <main className='main-container'>
          <div className='parent-container'>
            <div className='loading'></div>
          </div>
          </main>
        );
      }

    return (
        <main className='main-container'>
            <div className='grid-container'>
                <div className='tabular--wrapper'>
                    <div className='table-container'>
                        <table>
                            <thead>
                            <tr>
                                    <th>SAD</th>
                                    <th>Resever</th>
                                    <th>Amount</th>
                                    <th>Reason</th>
                                    <th>Date</th>
                                    <th>Submitter</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {formData.map(item => ( */}
                                    <tr>
                                        <td>{formData.SAD}</td>
                                        <td>{formData.resever}</td>
                                        <td>{formData.amount}</td>
                                        <td>{formData.reason}</td>
                                        <td>{formData.submission_date}</td>
                                        <td>{formData.submitter_name}</td>
                                        <td className={formData.status === 'pending' ? 'status-pending' : 'status-done'}>
                                            <button onClick={() => handleStatusChange(formData.id)} >{formData.status}</button>
                                        </td>
                                        {/* <td>
                                            <button className='but' onClick={() => updateDatas(formData.id)}><BsPencil /></button>
                                        </td> */}
                                        <td>
                                        {formData.status === 'pending' && (
                                                <>
                                                    {/* <button onClick={() => viewData(item.id)}><BsEyeFill /></button> */}
                                                    {/* <button onClick={() => deleteData(item.id)}><BsTrash /></button> */}
                                                    <button className='but' onClick={() => updateDatas(formData.id)}><BsPencil /></button>
                                                </>
                                            )}
                                                {formData.status === 'Done' && (
                                                    <div></div>
                                                )}
                                        </td>
                                    </tr>
                                {/* ))} */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ViewData;
