import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../style.css';
import Header from '../Header/Header';
import Nav from '../Nav/Nav';
import { useNavigate } from 'react-router-dom';

function Dtoday() {
    const [dashboardData, setDashboardData] = useState([]);
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('https://serverdb.rmaplc.com')
            .then(res => {
                if (res.data.valid) {
                    setName(res.data.username);
                } else {
                    navigate('/login');
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));

    }, []);

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        try {
            const response = await axios.get('https://serverdb.rmaplc.com/dashboard_data');
            setDashboardData(response.data);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    if (loading) {
        return (
          <main className='main-container'>
          <div className='parent-container'>
            <div className='loading'></div>
          </div>
          </main>
        );
      }
    


    return (
        <main className='main-container'>
        <div className='grid-container'>
            <div className='tabular--wrapper'>
                <div className='table-container'>
                    <table>
                        <thead>
                            <tr>
                                <th>SAD</th>
                                <th>Resever</th>
                                <th>Amount</th>
                                <th>Reason</th>
                                <th>Date</th>
                                <th>Submitter</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dashboardData.map(item => (
                                <tr key={item.id}>
                                    <td>{item.SAD}</td>
                                    <td>{item.resever}</td>
                                    <td>{item.amount}</td>
                                    {/* <td>{item.reason.length > 10 ? `${item.reason.slice(0, 10)}...` item.reason}</td> */}
                                    <td>{item.reason.length > 20 ? `${item.reason.slice(0, 20)}...` : item.reason}</td>
                                    <td>{formatDate(item.submission_date)}</td>
                                    <td>{item.submitter_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </main>
    );
}

export default Dtoday;



